const blue =  '#007bff'
const indigo = '#6610f2'
const purple = '#6f42c1'
const pink = '#e83e8c'
const red = '#dc3545'
const orange = '#fd7e14'
const yellow = '#ffc107'
const green = '#28a745'
const teal = '#20c997'
const cyan = '#17a2b8'
const gold = '#ccac00'
const silver = '#d4daf0'
const gray100 = '#f8f9fa'
const gray200 = '#e9ecef'
const gray300 = '#dee2e6'
const gray400 = '#ced4da'
const gray500 = '#adb5bd'
const gray600 = '#6c757d'
const gray700 = '#495057'
const gray800 = '#343a40'
const gray900 = '#212529'

const colors = {
    primary: '#9782ff',
    primaryLight: '#e8e3fe',
    primaryDark: '#5f28cd',
    secondary: '#f53c7b',
    success:  green,
    info: cyan,
    warning: yellow,
    danger:  red,
    light: gray200,
    gray: gray500,
    grayDark: gray700,
    white: '#fff',
    black: '#000',
    linkText: '#fff',
    star: '#ffcd01',
    textColor: '#373a3c',

    blue,
    indigo,
    purple,
    pink,
    red,
    orange,
    yellow,
    green,
    teal,
    cyan,
    gold,
    silver,

    gray100,
    gray200,
    gray300,
    gray400,
    gray500,
    gray600,
    gray700,
    gray800,
    gray900,
}



export default colors;
