import * as React from 'react';
import {Form} from 'react-bootstrap';

export default function Content ({small}) {
  return (<Form>
    <Form.Label style={{fontSize: '11px'}}>กรุณาเลือกเปิดฟังก์ชันนี้ไว้ หากภาพนี้เป็นภาพของบุคคล 
  หรือภาพที่สามารถระบุตัวตนของลูกค้าได้ ซึ่งจะเป็นการผิด
  ต่อกฎหมาย PDPA เพื่อคุ้มครองผู้บริโภค
    </Form.Label>
  </Form>)
}
