import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Row, Col, Container, Card, Table, Button, Form, InputGroup, FormControl,
  Accordion, Modal, Badge, Dropdown, Fade
} from 'react-bootstrap';
import './../assets/scss/style.scss';
import Aux from "../hoc/_Aux";
import logoDark from '../assets/images/logo-dark.png';
import Fullscreen from "react-full-screen";
import { BusinessInfo, Role, Additional } from './register/index'
import api from '../utils/Api'
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Condition, Policy } from '../utils/Data'
import withReactContent from 'sweetalert2-react-content';
import { FullscreenLoading } from '../components/Modals'
import { set } from 'react-ga';

const MySwal = withReactContent(Swal);

export default function Register(props) {
  const [isPersonal, setIsPersonal] = useState(false)
  const [showBusinessInfo, setShowBusinessInfo] = useState(false)
  const [showRole, setShowRole] = useState(false)
  const [showType, setShowType] = useState(false)
  const [showAdditional, setShowAdditional] = useState(false)
  const [signingUp, setSigningUp] = useState(false)
  const [user, setUser] = useState({})
  const [policy, setPolicy] = useState(false)
  const [consent, setConsent] = useState(null)
  const [loading, setLoading] = useState(false)

  const [form, setForm] = useState({
    user: {},
    business: {},
    role: {},
  })


  useEffect(() => {
    if (showBusinessInfo === false)
      setForm({ ...form, business: {} })
  }, [showBusinessInfo])

  useEffect(() => {
    if (showRole === false)
      setForm({ ...form, role: {} })
  }, [showRole])

  const signUp = (additional) => {
    const formData = {
      ...form.user,
      ...form.business,
      ...form.role,
      ...additional,
      is_personal: isPersonal
    }
    setLoading(true)
    setSigningUp(true)

    api.request(isPersonal ? 'shop/request-register-personal' : 'shop/request-register', (res, suc) => {
      setSigningUp(false)
      setLoading(false)
      if (suc) {
        setLoading(false)
        MySwal.fire({
          title: 'สมัครสมาชิกสำเร็จ !', type: 'success',
          text: 'ขอบคุณสำหรับการสมัครใช้งาน กรุณารอทีมงานตรวจสอบข้อมูล และจะแจ้งให้ทราบทางอีเมล',
          confirmButtonText: 'กลับสู่หน้าหลัก'
        }).then(() => window.location = window.location.hostname.match(/admin\-dev/g) || window.location.hostname.match(/localhost/g) ? 'https://dev.hubswitch.com' : 'https://hubswitch.com');
      }
    }, api.form(formData), 'POST')
  }

  return (
    <Aux>
      <div className="full-screen row-vcenter bg-white register">
        <Container>
          <Row>
            <Col sm={{ span: 12, offset: 0 }} md={{ span: 6, offset: 3 }}>
              <Row>
                <Col xs={12} className="mb-5">
                  <h2 className="text-primary text-center">Create Account</h2>
                </Col>
                <Col xs={12} md={6}>
                  <InputGroup className="mb-4">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <i className="fa fa-user"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      placeholder="Name"
                      onChange={(e) => setUser({
                        ...user,
                        name: e.target.value
                      })}
                    />
                  </InputGroup>
                </Col>
                <Col xs={12} md={6}>
                  <InputGroup className="mb-4">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <i className="fa fa-user"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      placeholder="Lastname"
                      onChange={(e) => setUser({
                        ...user,
                        lastname: e.target.value
                      })}
                    />
                  </InputGroup>
                </Col>
                <Col xs={12}>
                  <InputGroup className="mb-4">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <i className="fa fa-envelope"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      placeholder="Email"
                      onChange={(e) => setUser({
                        ...user,
                        email: e.target.value
                      })}
                    />
                  </InputGroup>
                </Col>
                <Col xs={12}>
                  <InputGroup className="mb-4">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <i className="fa fa-lock"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      onChange={(e) => setUser({
                        ...user,
                        password: e.target.value
                      })}
                    />
                  </InputGroup>
                </Col>
                <Col xs={12}>
                  <InputGroup className="mb-4">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <i className="fa fa-lock"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="password"
                      placeholder="Confirm Password"
                      onChange={(e) => setUser({
                        ...user,
                        confirm_password: e.target.value
                      })}
                    />
                  </InputGroup>
                </Col>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Check
                      inline
                      custom
                      value={policy}
                      label={''}
                      type="checkbox"
                      //feedback={this.state.supportedRadio ? false : "More example invalid feedback text."}
                      onChange={(v) => {
                        setPolicy(!policy)
                      }}
                    />
                    <Form.Label>
                      คุณได้อ่านและยอมรับ {<a className="link" onClick={() => {
                        setConsent('condition')
                      }}>{'เงื่อนไขการใช้บริการ'}</a>} และ {<a className="link" onClick={() => {
                        setConsent('policy')
                      }}>{'นโยบายความเป็นส่วนตัว'}</a>}
                    </Form.Label>
                  </Form.Group>
                </Col>
                <Col xs={12} className="row-center mt-3">
                  <Button onClick={() => {
                    if (user.confirm_password != user.password) {
                      toast.warn('รหัสผ่านไม่ตรงกัน')
                      return
                    }

                    if (!user.password) {
                      toast.warn('กรุณากรอกรหัสผ่าน')
                      return
                    }

                    if (user.password.length < 6) {
                      toast.warn('กรุณากรอกรหัสผ่าน 6 ตัวอักษรขึ้นไป')
                      return
                    }

                    if (!user.name) {
                      toast.warn('กรุณากรอกชื่อ')
                      return
                    }

                    if (!user.email) {
                      toast.warn('กรุณากรอกอีเมล')
                      return
                    }


                    setShowType(true)


                  }} size="md" disabled={!policy} variant="secondary">Next</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Modal size="md" centered onHide={() => setShowType(false)} show={showType}>
          <Row style={{ width: 500, maxWidth: '100%' }} className="p-3">
            <Col xs={12} className='mb-3'>
              <h3 className="text-primary text-center">เปิดบัญชีกับ Hubswitch</h3>
            </Col>
            <Col xs={6} className="row-center">
              <div onClick={() => setIsPersonal(false)} className={"role-selector cursor-pointer row-center shadow" + (!isPersonal ? ' role-selector-active' : '')}>
                <span>บัญชีบริษัท</span>
              </div>
            </Col>
            <Col xs={6} className="row-center">
              <div onClick={() => setIsPersonal(true)} className={"role-selector cursor-pointer row-center shadow" + (isPersonal ? ' role-selector-active' : '')}>
                <span>บัญชีส่วนบุคคล</span>
              </div>
            </Col>
            <Col xs={12} className="row-center mt-3">
              <Button onClick={() => {
                if (isPersonal) {
                  setForm({
                    ...form,
                    user: {
                      name: user.name + ' ' + user.lastname,
                      email: user.email,
                      password: user.password
                    }
                  })

                  setShowType(false)
                  setShowBusinessInfo(true)

                  return
                }

                api.request('shop/check-register', (res, s) => {
                  if (!s) return

                  setForm({
                    ...form,
                    user: {
                      name: user.name + ' ' + user.lastname,
                      email: user.email,
                      password: user.password
                    }
                  })

                  setShowType(false)
                  setShowBusinessInfo(true)

                }, api.form({
                  name: user.name + ' ' + user.lastname,
                  email: user.email,
                  password: user.password,
                }), 'POST')

              }} size="md" variant="secondary">Next</Button>
            </Col>
          </Row>
        </Modal>
        <Modal size="lg" className="full-screen" onHide={() => setShowBusinessInfo(false)}
          centered show={showBusinessInfo}>
          <BusinessInfo isPersonal={isPersonal} onCancel={() => {
            setShowType(true)
            setShowBusinessInfo(false)
          }} onNext={(data) => {
            if (isPersonal) {
              signUp(data)
            } else {
              setShowRole(true)
              setForm({ ...form, business: data })
            }
          }} />
        </Modal>
        <Modal animation={false} size="lg" className="full-screen" onHide={() => setShowRole(false)}
          centered show={showRole}>
          <Role onCancel={() => setShowRole(false)} onNext={(data) => {
            setShowAdditional(true)
            setForm({ ...form, role: data })
          }} />
        </Modal>
        <Modal animation={false} size="lg" className="full-screen" onHide={() => setShowAdditional(false)}
          centered show={showAdditional}>
          <Additional signingUp={signingUp} onCancel={() => setShowAdditional(false)} onNext={(data) => {
            signUp(data)
          }} />
        </Modal>
        <Modal scrollable centered show={consent == 'condition'} onHide={() => {
          setConsent(null)
        }}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h4>เงื่อนไขการใช้บริการ</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Condition />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => {
              setConsent(null)
            }}>Close</Button>
          </Modal.Footer>
        </Modal>
        <Modal scrollable centered show={consent == 'policy'} onHide={() => {
          setConsent(null)
        }}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h4>นโยบายความเป็นส่วนตัว</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Policy />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => {
              setConsent(null)
            }}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div style={{ position: 'absolute', top: 40, left: 40 }}>
        <h4 className="text-muted cursor-pointer">HubSwitch</h4>
      </div>
      <ToastContainer autoClose={3000} />
      <FullscreenLoading show={loading} />
    </Aux>
  );
}
