import React from 'react'
import WordingContext from '../contexts/wording'
import api from '../utils/Api'

const withWordingContext = Component => (
  class WithWordingContextHOC extends React.PureComponent {
    currentUser = JSON.parse(localStorage.getItem('user'))

    constructor(props) {
      super(props)
      this.state = {
        wording: {},
        wordingData: {},
        allWordingData: [],
        currentShopID: null,
        l: 'th',
        typeName: '',
      }
    }

    async componentDidMount() {
       const allWordingData = await this.getAllWordingData() || []
       this.setState({ allWordingData })
    }

    setCurrentShopID = (currentShopID) => {
      if (currentShopID) {
        this.setState({ currentShopID }, this.fetchWording)
      } else {
        this.setState({
          currentShopID: null,
          wording: {},
          wordingData: {},
          typeName: '',
        })
      }
    }

    getShopData = (shopID) => new Promise((resolve, reject) => {
      api.request('shop/' + (this.state.currentShopID || this.currentUser.user.shop_id), (shopItem)=>{
        resolve(shopItem)
      })
    })

    getAllWordingData = () => new Promise((resolve, reject) => {
      api.request(`/business-types`, (data) => {
        resolve(data)
      })
    })

    getWordingData = (typeID) => new Promise((resolve, reject) => {
      api.request(`/business-types/${typeID}`, (data) => {
        resolve(data)
      })
    })

    getLang = () => new Promise((resolve, reject) => {
      api.request('profile', (response) => {
        resolve(response.setting && response.setting.lang ? response.setting.lang : 'en')
      })
    })

    fetchWording = async () => {
      if (!this.state.currentShopID && !this.currentUser.user.shop_id) return
      try {
        const shopData = await this.getShopData()
        const { typeID, type } = shopData
        const response = type
        const wordingData = response?.wording || {}
        // const l = await this.getLang()
        const l = 'th'
        const typeName = response?.name?.[l]
        let wording = JSON.parse(JSON.stringify(wordingData))
        Object.keys(wording).forEach((key) => {
          wording[key] = wording[key][l]
        })
        return this.setState({ wording, l, wordingData, typeName, typeID })
      } catch (e) {
        console.warn('fetch wording error', e)
        return
      }
    }

    getWordingByID = (id) => {
      return this.state.allWordingData.find((item) => item.id === id)?.wording
    }

    isUnattachedStation = (id) => {
      const _id = id ? id : this.state.typeID
      return this.state.allWordingData.find((item) => item.id === _id)?.unattached_station
    }

    isCustomWorkday = (id) => {
      const _id = id ? id : this.state.typeID
      return this.state.allWordingData.find((item) => item.id === _id)?.custom_workday
    }

    getPluralWord = (word) => {
      if (!word) return word

      const plural = {
        '(quiz)$': '$1zes',
        '^(ox)$': '$1en',
        '([m|l])ouse$': '$1ice',
        '(matr|vert|ind)ix|ex$': '$1ices',
        '(x|ch|ss|sh)$': '$1es',
        '([^aeiouy]|qu)y$': '$1ies',
        '(hive)$': '$1s',
        '(?:([^f])fe|([lr])f)$': '$1$2ves',
        '(shea|lea|loa|thie)f$': '$1ves',
        sis$: 'ses',
        '([ti])um$': '$1a',
        '(tomat|potat|ech|her|vet)o$': '$1oes',
        '(bu)s$': '$1ses',
        '(alias)$': '$1es',
        '(octop)us$': '$1i',
        '(ax|test)is$': '$1es',
        '(us)$': '$1es',
        '([^s]+)$': '$1s',
      }
      const irregular = {
        move: 'moves',
        foot: 'feet',
        goose: 'geese',
        sex: 'sexes',
        child: 'children',
        man: 'men',
        tooth: 'teeth',
        person: 'people',
      }
      const uncountable = [
        'sheep',
        'fish',
        'deer',
        'moose',
        'series',
        'species',
        'money',
        'rice',
        'information',
        'equipment',
        'bison',
        'cod',
        'offspring',
        'pike',
        'salmon',
        'shrimp',
        'swine',
        'trout',
        'aircraft',
        'hovercraft',
        'spacecraft',
        'sugar',
        'tuna',
        'you',
        'wood',
      ]

      if (uncountable.indexOf(word.toLowerCase()) >= 0) {
        return word
      }

      for (const w in irregular) {
        const pattern = new RegExp(`${w}$`, 'i')
        const replace = irregular[w]
        if (pattern.test(word)) {
          return word.replace(pattern, replace)
        }
      }

      for (const reg in plural) {
        const pattern = new RegExp(reg, 'i')
        if (pattern.test(word)) {
          return word.replace(pattern, plural[reg])
        }
      }
      return word
    }

    render() {
      const contextValue = {
        ...this.state,
        setCurrentShopID: this.setCurrentShopID,
        fetchWording: this.fetchWording,
        getWordingByID: this.getWordingByID,
        getPluralWord: this.getPluralWord,
        isUnattachedStation: this.isUnattachedStation,
        isCustomWorkday: this.isCustomWorkday
      }

      return (
        <WordingContext.Provider value={contextValue}>
          <Component {...this.props} {...contextValue} />
        </WordingContext.Provider>
      )
    }
  }
)

export default withWordingContext
