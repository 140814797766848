import * as React from 'react';
import {Form} from 'react-bootstrap';

export default function Content ({small}) {
  return (<Form>
    <Form.Label style={{fontSize: '11px'}}>การเผยแพร่รูปนี้ของลูกค้า จะถูกลบออกจากระบบหากครบกำหนด
  แต่ถ้าหากลูกค้าไม่อนุญาต หรือยกเลิกคำยินยอมให้เผยแพร่
  รูปก็จะถูกลบเช่นกัน แต่จะยังถูกเก็บอยู่ในประวัติของลูกค้า
  โดยไม่ถูกลบออกจากระบบ
    </Form.Label>
  </Form>)
}
