import * as React from 'react';
import {Form} from 'react-bootstrap';

export default function Content ({small}) {
  return (<Form>
    <Form.Label style={{fontSize: '11px'}}>โปรดเลือกช่วงเวลา เพื่อทำการระบุช่วงเวลาในการเผยแพร่รูป
  ของลูกค้า หากครบกำหนด รูปจะถูกลบออกจากระบบ และถ้าหาก
  ลูกค้าไม่อนุญาต หรือยกเลิกคำยินยอมให้เผยแพร่ รูปก็จะถูกลบ
  เช่นกัน แต่จะไม่หายไปจากประวัติของลูกค้า
    </Form.Label>
  </Form>)
}
