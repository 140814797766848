import _ from "lodash";
import trans from '../locales/trans'

export const getLimitedNumber = (number, limit) => {
    if (number <= limit) {
        return number;
    }

    return limit + '+';
}


export function fileNameAndExt(str){
  var file = str.split('/').pop();

  return file.substr(0,file.lastIndexOf('.')) +'.'+file.substr(file.lastIndexOf('.')+1,file.length);
}

export function getQueryParams(search, raw = false) {
  if (!raw) {
    return Object.fromEntries(new URLSearchParams(search))
  }

  let params = {}

  search.substr(1).split('&').reduce((a, b) => {
    let [key, val] = b.split('=');
    a[key] = val;
    return a;
  }, params)

  return params
}

export function numberFormat(num, hideDecimalWhenInteger = false, zeroText = '0') {
    var tester = parseFloat(num);

    if (num === 0 || num === null || num === undefined || num === '') {
        if (!hideDecimalWhenInteger && zeroText==='0') {
          return '0.00'
        }

        return zeroText;
    }

    if (tester > Math.floor(tester) ) {
        num = tester;
        num = num.toFixed(2);
    } else if (hideDecimalWhenInteger) {
        if (num != '' && num != null){
            num = Math.floor(num);
        }
    } else if (tester == Math.floor(tester) && !hideDecimalWhenInteger) {
      num = parseFloat(num).toFixed(2);
    }

    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};


export function numberFormatWithOneDecimal(num, hideDecimalWhenInteger = false, zeroText = '0') {
  var tester = parseFloat(num);

  if (num === 0 || num === null || num === undefined || num === '') {
      if (!hideDecimalWhenInteger && zeroText==='0') {
        return '0'
      }

      return zeroText;
  }

  if (tester > Math.floor(tester) ) {
      num = tester;
      num = num.toFixed(1);
  } else if (hideDecimalWhenInteger) {
      if (num != '' && num != null){
          num = Math.floor(num);
      }
  } else if (tester == Math.floor(tester) && !hideDecimalWhenInteger) {
    num = parseFloat(num).toFixed(1);
  }

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function currencyFormat(num, currencyText = '฿') {
  return currencyText + numberFormat(num, 2, '0')
};


export function numberInRange(number, min, max) {
    return Math.max(Math.min(number, max), min);
}

export const optionValue = (_id, opts) => {
    if (_id === undefined || _id === null || _id === '') {
        return null
    }

    return _.find(opts, ['value', _id])
}

export function convertDurationToText (duration, unit, omitZero = false) {
  let firstDuration = 0
  let secondDuration = 0
  let primaryText = '';
  let secondaryText = '';
  let firstUnit = '';
  let secondUnit = '';

  if (unit == 'hour') {
    firstDuration = Math.floor(duration/24)
    secondDuration = duration%24
    firstUnit = trans('วัน')
    secondUnit = trans('ชม.')
  } else if (unit == 'minute') {
    firstDuration = Math.floor(duration/60)
    secondDuration = duration%60
    firstUnit = trans('ชม.')
    secondUnit = trans('นาที')
  }

  if (omitZero) {
    if (firstDuration) {
      primaryText = `${firstDuration} ${firstUnit}`
    }
    if (secondDuration) {
      secondaryText = ` ${secondDuration} ${secondUnit}`
    }
  } else {
    primaryText = `${firstDuration} ${firstUnit} `
    secondaryText = `${secondDuration} ${secondUnit}`
  }

  return primaryText+secondaryText
}

export const textFilter = (item, inputValue) => {
    if (inputValue) {
        const regexp = new RegExp(inputValue, 'i')
        return item.label.match(regexp)
    }

    return true
}

export const addLeadingZeros = (num, totalLength) => {
    return String(num).padStart(totalLength, '0');
}
