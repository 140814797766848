import * as React from 'react';
import {Form} from 'react-bootstrap';

export default function Content ({small}) {
  return (<Form>
    <Form.Label style={{fontSize: '11px'}}>การเพิ่มข้อความในส่วนนี้ มีไว้สำหรับเผยแพร่ให้สาธารณะและลูกค้า
  เจ้าของภาพเห็น ถึงแม้จะไม่ได้เผยแพร่ภาพลงสู่สาธารณะ แต่ถ้าพิมพ์
  ข้อความไว้ในส่วนนี้ ข้อความก็จะไปปรากฎที่ลูกค้าเจ้าของภาพ
    </Form.Label>
  </Form>)
}
