import * as React from 'react';

export default function condition({small}){
    return(
        <>
        <h6>
        บริษัท ฮับสวิตช์ จำกัด ("บริษัทฯ” หรือ “เรา”) ต้องการขอความยินยอมจากท่านเพื่อให้บริษัทฯ สามารถเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคลและข้อมูลส่วนบุคคลที่มีความอ่อนไหวของท่าน เพื่อวัตถุประสงค์ที่ได้ระบุไว้ตามรายละเอียดของการขอความยินยอมนี้ สำหรับรายละเอียดเพิ่มเติมเกี่ยวกับวิธีการที่บริษัทฯ ปกป้องข้อมูลส่วนบุคคลของท่านและสิทธิที่ท่านมีภายใต้พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 โปรดดูนโยบายความเป็นส่วนตัวของเราได้ที่ https://hubswitch.com/privacy-policy2/
        </h6><h6>
	การที่ท่านคลิกในช่องข้างล่างนี้ ถือว่าท่านได้อ่านและเข้าใจในข้อมูลทั้งหมดที่ได้ระบุไว้ในแต่ละวัตถุประสงค์ รวมถึงนโยบายความเป็นส่วนตัวของบริษัทฯ อย่างชัดเจนแล้วก่อนท่านได้ให้ความยินยอม
    </h6><h6>
1.	เพื่อให้บริษัทฯ สามารถเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลของท่าน (อันได้แก่ ชื่อ-นามสกุล เบอร์โทรศัพท์ อีเมล เป็นต้น) เพื่อวัตถุประสงค์ในการอนุญาตให้บริษัทฯ สามารถส่งข่าวสาร รายการส่งเสริมการขาย ข้อมูลที่เกี่ยวข้องกับสินค้าหรือบริการของบริษัทฯ หรือข้อเสนอของบริษัทฯ ที่ท่านอาจให้ความสนใจ
</h6><h6>
	ข้าพเจ้า ยินยอม ให้บริษัทฯ เก็บรวบรวมและใช้ข้อมูลส่วนบุคคลของข้าพเจ้าเพื่อวัตถุประสงค์ที่ระบุไว้	ข้างต้นและข้าพเจ้าขอยืนยันว่าได้อ่านและเข้าใจรายละเอียดทั้งหมดของการขอความยินยอมนี้แล้ว
    </h6><h6>
	ใช่ ข้าพเจ้า ยินยอม ให้บริษัทฯ เก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลที่มีความอ่อนไหวของ	ข้าพเจ้า	เพื่อวัตถุประสงค์ที่ระบุไว้ข้างต้นและข้าพเจ้าขอยืนยันว่าได้อ่านและเข้าใจรายละเอียดทั้งหมดของ	การขอความยินยอมนี้แล้ว
    </h6><h6>
	ไม่ ข้าพเจ้า ไม่ยินยอม ให้บริษัทฯ ประมวลผลข้อมูลส่วนบุคคลที่มีความอ่อนไหวของข้าพเจ้าเพื่อ	วัตถุประสงค์ข้างต้น
    </h6><h6>
หากท่านมีความประสงค์ที่จะถอนความยินยอมของท่าน ท่านสามารถทำได้โดยการแจ้งบริษัทฯ ผ่านทางอีเมล Info@hubswitch.com 
</h6><h6>
	ทั้งนี้ หากท่านถอนความยินยอม ท่านยังคงสามารถติดต่อและใช้สินค้าหรือบริการของบริษัทฯ ต่อไปตามภาระผูกพันทางสัญญาที่ท่านมีกับบริษัทฯ ได้ อย่างไรก็ตาม ท่านอาจพบเจอกับความไม่สะดวกหรือพลาดการรับข้อมูลที่อาจเป็นประโยชน์กับท่านหรือท่านอาจไม่ได้รับความสะดวกในการใช้บริการบนแพลตฟอร์ม HubSwitch

</h6></>
    )
}