import React from 'react';
import {NavLink} from 'react-router-dom';
import {Row, Col, Container, Card, Table, Button, Form, InputGroup, FormControl,
   Accordion, Modal, Badge, Dropdown, Fade} from 'react-bootstrap';
import './../assets/scss/style.scss';
import Aux from "../hoc/_Aux";
import Breadcrumb from "../App/layout/AdminLayout/Breadcrumb";
import logoDark from '../assets/images/logo-dark.png';
import api from '../utils/Api'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import moment from 'moment'

const MySwal = withReactContent(Swal);

class Login extends React.Component {
    state = {
      email: '',
      password: '',
      forgotPass: false,
      setNewPassword: false,
      resetEmail: '',
      otp: '',
      newPassword: '',
      confirmPassword: '',
      submitting: false,
      redirectTo: '/'
    }

    componentDidMount() {
      let search = this.props.location.search;
      const {state, hash} = this.props.history.location
      let params = new URLSearchParams(search);
      if (state?.from) {
        this.setState({redirectTo: state.from.pathname + state.from.search})
      }
      const qs = Object.fromEntries(params)

      const {email, token} = qs

      if (!email || !token) {
        return
      }

      api.request('profile/verify', (res, suc)=>{
        if (suc) {
          MySwal.fire({
            title: 'ยืนยันอีเมลสำเร็จ !', type: 'success',
            text: 'คุณสามารถเข้าสู่ระบบ เพื่อเริ่มใช้งานได้ทันที',
          })
        } else {
          MySwal.fire({
            title: 'ยืนยันอีเมลล้มเหลว !', type: 'error',
            text: 'กรุณาลองอีกครั้ง หรือติดต่อทีมงาน',
          })
        }
      }, {email, token}, 'GET')
    }

    login = () => {
      const {email, password} = this.state

      api.request('login', (response, success)=>{
        if (success) {
          const {token, user} = response
          localStorage.setItem('api_token', token.token)
          localStorage.setItem('user', JSON.stringify(user))
          localStorage.setItem('latestFillingPin', moment().toISOString())
          this.props.history.replace(this.state.redirectTo)
        }
      }, api.form({email, password}), 'POST')
    }

    render () {

        return(
            <Aux>
                <Breadcrumb/>
                <div className="auth-wrapper">
                    <div className="auth-content">
                        <div className="card">
                            <div className="row align-items-center text-center">
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <img src={require('../assets/logo-text-primary.png')} alt="" style={{width: 100}} className="img-fluid mb-5" />
                                        <div className="input-group mb-3">
                                            <input onKeyDown={(e)=>{
                                              if (e.key === 'Enter') {
                                                this.login()
                                              }
                                            }} onChange={(e)=>this.setState({email: e.target.value})} type="email" className="form-control" placeholder="Email address"/>
                                        </div>
                                        <div className="input-group mb-4">
                                            <input onChange={(e)=>this.setState({password: e.target.value})}
                                              onKeyDown={(e)=>{
                                                if (e.key === 'Enter') {
                                                  this.login()
                                                }
                                              }}
                                              type="password" className="form-control" placeholder="Password"/>
                                        </div>
                                        <div className="text-center mb-3">
                                          <a onClick={()=>this.setState({forgotPass: true})} className="text-muted" href="#">Forgot your password ?</a>
                                        </div>
                                        {/*<div className="form-group text-left mt-2">
                                            <div className="checkbox checkbox-primary d-inline">
                                                <input type="checkbox" name="checkbox-fill-1" id="checkbox-fill-a1" checked=""/>
                                                <label htmlFor="checkbox-fill-a1" className="cr"> Save credentials</label>
                                            </div>
                                        </div>
                                        */}
                                        <button onClick={()=>{
                                          this.login()
                                        }} className="btn btn-block btn-primary mb-4">Log In</button>
                                        {/*<p className="mb-2 text-muted">Forgot password? <NavLink to="/auth/reset-password-1" className="f-w-400">Reset</NavLink></p>
                                        <p className="mb-0 text-muted">Don’t have an account? <NavLink to="/auth/signup-1" className="f-w-400">Signup</NavLink></p>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal animation={true} size="md" onHide={()=>this.setState({forgotPass: false})}
                 centered show={this.state.forgotPass}>
                    <Modal.Body>
                      <h4 className="mb-4">Fill your email</h4>
                      <div className="my-2">
                        <span className="text-muted">We will send otp to your email</span>
                      </div>
                      <div className="input-group mb-3">
                          <input onChange={(e)=>this.setState({resetEmail: e.target.value})} type="email" className="form-control" placeholder="Email address"/>
                      </div>
                      <button onClick={()=>{
                        if (this.state.submitting) {
                          return
                        }

                        this.setState({submitting: true})
                        api.request('user/create-otp', (response, success)=>{
                          this.setState({submitting: false})
                          if (success) {
                            this.setState({setNewPassword: true, forgotPass: false})
                          }
                        }, api.form({email: this.state.resetEmail}), 'POST')
                      }} className="btn btn-block btn-primary my-4">Submit</button>
                    </Modal.Body>
                </Modal>
                <Modal animation={true} size="md" onHide={()=>this.setState({setNewPassword: false})}
                 centered show={this.state.setNewPassword}>
                    <Modal.Body>
                      <h5 className="mb-4">Fill otp and set new password</h5>
                      <div className="input-group mb-3">
                          <input onChange={(e)=>this.setState({otp: e.target.value})} type="text" className="form-control"
                          placeholder="OTP"/>
                      </div>
                      <div className="input-group mb-3">
                          <input onChange={(e)=>this.setState({newPassword: e.target.value})} type="password" className="form-control"
                          placeholder="New Password"/>
                      </div>
                      <div className="input-group mb-3">
                          <input onChange={(e)=>this.setState({confirmPassword: e.target.value})} type="password" className="form-control"
                          placeholder="Confirm Password"/>
                      </div>
                      <button onClick={()=>{
                        if (this.state.submitting) {
                          return
                        }

                        if (this.state.newPassword != this.state.confirmPassword) {
                            toast.warn('รหัสผ่านไม่ตรงกัน')
                            return
                        }

                        this.setState({submitting: true})
                        api.request('user/set-new-password', (response, success)=>{
                          this.setState({submitting: false})
                          if (success) {

                            this.setState({setNewPassword: false})
                            MySwal.fire({
                              title: 'รีเซตรหัสผ่านสำเร็จ !', type: 'success',
                              text: 'รหัสผ่านของคุณถูกตั้งใหม่แล้ว',
                            })
                          }
                        }, api.form({email: this.state.resetEmail, password: this.state.newPassword, reset_code: this.state.otp}), 'PATCH')
                      }} className="btn btn-block btn-primary my-4">Submit</button>
                    </Modal.Body>
                </Modal>
            </Aux>
        );
    }
}

export default Login;
