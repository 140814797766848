import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {Row, Col, Container, Card, Table, Button, Form, InputGroup, FormControl,
   Accordion, Modal, Badge, Dropdown, Fade} from 'react-bootstrap';
import '../../assets/scss/style.scss';
import Aux from "../../hoc/_Aux";
import Fullscreen from "react-full-screen";
import { toast } from 'react-toastify';

const functions = [{
  label: 'การนัดหมาย',
  value: 'booking'
}, {
  label: 'บัญชี',
  value: 'account'
},
{
  label: 'การคลัง',
  value: 'inventory'
},
{
  label: 'จัดการทรัพยากรบุคคล',
  value: 'hr'
},
{
  label: 'ข้อความ',
  value: 'message'
},
{
  label: 'ลูกค้าสัมพันธ์',
  value: 'crm'
},]

const staffNumber = [{
  label: '1-10',
  value: '1-10'
}, {
  label: '11-30',
  value: '11-30'
},
{
  label: '31-50',
  value: '31-50'
},
{
  label: 'มากกว่า 50',
  value: 'มากกว่า 50'
}]

const businessLength = [{
  label: 'น้อยกว่า 1',
  value: 'น้อยกว่า 1'
}, {
  label: '1-3',
  value: '1-3'
},
{
  label: '3-7',
  value: '3-7'
},
{
  label: 'มากกว่า 7',
  value: 'มากกว่า 7'
}]

export default function Role({onNext, onCancel, signingUp = false}) {
  const [year, setYear] = useState(businessLength[0].value)
  const [staff, setStaff] = useState(staffNumber[0].value)
  const [systems, setSystems] = useState([])

  return (
    <Aux>
        <div className="full-screen bg-white">
          <Container fluid>
            <Row>
              <Col className="register-process-indicator column-center py-4" sm={12} lg={5}>
                <h3 className="text-white">ข้อมูลเพิ่มเติม</h3>
                <i className="fa fa-info-circle text-white register-process-indicator-icon"
                ></i>
                <div className="row-vcenter">
                  <div className="register-process-indicator-bar" />
                  <div className="register-process-indicator-bar mx-2" />
                  <div className="register-process-indicator-bar active" />
                </div>
              </Col>
              <Col sm={12} lg={7}>
                <div className="d-flex flex-column justify-content-around align-items-center h-100">
                  <Row style={{width: 500, maxWidth: '100%'}}>
                    <Col xs={12}>
                        <h3 className="text-primary text-center">บอกเราเพิ่มเติมเกี่ยวกับธุรกิจของคุณ</h3>
                    </Col>
                    <Col xs={12} className="mt-5 mb-4">
                        <div>ระยะเวลาการดำเนินธุรกิจ(ปี):</div>
                        <div className="row-center">
                          <div className="addtional-selector-container shadow">
                            { businessLength.map((item, index)=>(
                              <div onClick={()=>setYear(item.value)} key={index} className={"addtional-selector"+(item.value==year?' addtional-selector-active':'')}>
                                {item.label}
                              </div>
                            ))
                            }
                          </div>
                        </div>
                    </Col>
                    <Col xs={12} className="mt-2 mb-4">
                        <div>จำนวนพนักงานในระบบ:</div>
                        <div className="row-center">
                          <div className="addtional-selector-container shadow">
                          { staffNumber.map((item, index)=>(
                            <div onClick={()=>setStaff(item.value)} key={index} className={"addtional-selector"+(item.value==staff?' addtional-selector-active':'')}>
                              {item.label}
                            </div>
                          ))
                          }
                          </div>
                        </div>
                    </Col>
                    <Col xs={12} className="mt-2 mb-4">
                        <div>ฟังก์ชันที่คุณต้องการ:</div>
                        { functions.map((item, index)=>(
                          <div key={index}>
                            <div className="switch d-inline">
                              <Form.Control defaultChecked={false}
                               onChange={(e)=>{
                                 const _systems = [...systems]
                                 const index = _systems.indexOf(item.label)

                                 if (index > -1 && !e.target.checked) {
                                   _systems.splice(index, 1)
                                 } else if (e.target.checked) {
                                   _systems.push(item.label)
                                 }

                                 setSystems(_systems)
                               }}
                               type="checkbox" id={item.value} />
                              <Form.Label htmlFor={item.value} className="cr mr-2" />
                              {item.label}
                            </div>
                          </div>
                        ))
                        }
                    </Col>
                    <Col xs={12} className="row-center mt-3">
                      <Button onClick={()=>onCancel()} disabled={signingUp} className="mr-3" size="md" variant="dark">Back</Button>
                      <Button onClick={()=>{
                        if (!systems.length) {
                          toast.warn('กรุณาเลือกการใช้งานที่ต้องการ อย่างน้อย 1 ระบบ')
                          return
                        }

                        onNext({years: year, employee_no: staff, functions: systems.join(', ')})
                      }} size="md" disabled={signingUp} variant="secondary">Sign up</Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
    </Aux>
  )
}
