import th from './th'

var _lang = 'en'

export default function trans(key){

    if (typeof key != 'string'){
      return key
    }

    const locale = LanguageContext.get()

    return locale == 'th' && typeof th[key] != 'undefined' ? th[key] : key;
};


export const LanguageContext = {
  set:(lang) => {
    _lang = lang
  },
  get:()=> {
    return _lang
  }
}
