import React, {useState, useEffect} from 'react';
import Chart from "react-apexcharts";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {
    Container,
    Row,
    Col,
    Card,
    Table,
    Form,
    Button,
    Modal,
    Dropdown
} from 'react-bootstrap';

import Aux from "../../hoc/_Aux";
import DEMO from "../../store/constant";
import avatar2 from '../../assets/images/user/avatar-2.jpg';
import avatar3 from '../../assets/images/user/avatar-3.jpg';
import avatar4 from '../../assets/images/user/avatar-4.jpg';
import _ from 'lodash'
import postImage from '../../assets/images/gallery-grid/masonry-6.jpg';
import api from '../../utils/Api'
import Colors from '../../utils/styles/Colors'
import {can} from '../../utils/Helpers'
import {numberFormat, getQueryParams} from '../../utils/Functions'
import moment from 'moment'
import t from '../../locales/trans'

const numberStatsHeight = 175
const graphHeight = 350
const graphColors = [Colors.secondary, Colors.primaryDark, Colors.primary, Colors.gray300, Colors.gray200]
const labelWidth = 200
const rand = (min, max) => {
  return Math.floor(Math.random() * max) + min
}

export default function PendingIncomeChart({location}) {
  let params = getQueryParams(location.search, true)

  const [stats, setStats] = useState([])
  const lastDate = moment().endOf('month').format('D')
  const {period} = params

  const fetchStats = () => {
    api.request('account/stats/income-by-period', (data)=>{
      let _data = []

      if (period == 'year') {
        data.map(({name, completed, pending})=>{
          _data.push({
            name,
            'เก็บเงินแล้ว': completed,
            'รายได้รวม': pending,
          })
        })
      } else {
        const this_month = new Date(moment().endOf('month').toISOString()).getDate()
        for (var i = 1; i <= this_month; i++){
            var total_completed = 0
            var total_pending = 0
            for(var item of data){
                const day = new Date(item.date).getDate()
                if (day == i){
                    if(item.state == "completed"){
                        total_completed += item.amount
                    }else if(item.state != "cancelled"){
                        total_pending += item.amount
                    }
                }
            }

            _data.push({
              name: i,
              'เก็บเงินแล้ว': total_completed,
              'รายได้รวม': total_pending,
            })
        }
      }

      setStats(_data)
    }, {period}, 'GET', {bearerToken: params.api_token})
  }

  useEffect(()=>{
    fetchStats()
  }, [])

  return (
    <Aux>
        <div className="full-screen bg-white">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <ResponsiveContainer width="100%" height={graphHeight*0.75}>
                    <BarChart data={stats} barSize={10} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                        <defs>
                            <linearGradient id="colorBar1" x1="0" y1="0" x2="1" y2="0">
                                <stop offset="1%" stopColor="#1de9b6" stopOpacity={1}/>
                                <stop offset="99%" stopColor="#1dc4e9" stopOpacity={1}/>
                            </linearGradient>
                            <linearGradient id="colorBar2" x1="0" y1="0" x2="1" y2="0">
                                <stop offset="1%" stopColor="#899FD4" stopOpacity={1}/>
                                <stop offset="99%" stopColor="#A389D4" stopOpacity={1}/>
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip
                          labelFormatter={(label)=>((period =='month'?'วันที่ ':'')+label)}
                          formatter={(value, name, props)=> {
                            return [name=='รายได้รวม'?((parseInt(props.payload['รายได้รวม'])+parseInt(props.payload['เก็บเงินแล้ว'])).toString()):value, name]
                          }}
                        />
                        {/*<Legend verticalAlign="top" iconType="circle" height={40} formatter={(value, entry, index)=>(
                          index == 0 ? value + ' ' + (numberFormat(_.sumBy(stats, 'เก็บเงินแล้ว')) + ' THB') : value + ' '+ (numberFormat(_.sumBy(stats, (i)=>(i['เก็บเงินแล้ว']+i['รายได้รวม']))) + ' THB')
                        )} />*/}
                        <Bar dataKey="เก็บเงินแล้ว" stackId="bar" fill={Colors.success} radius={[10, 10, 10, 10]} />
                        <Bar dataKey="รายได้รวม" stackId="bar" fill={Colors.gray300} radius={[10, 10, 0, 0]} />
                    </BarChart>
                </ResponsiveContainer>
            </Col>
          </Row>
          </Container>
        </div>
    </Aux>
  )
}
