const th = {
  "If you don’t have account, please": "หากคุณยังไม่มีบัญชี",
  "Sign up": "สมัครสมาชิกที่นี่",
  "Set new password/forgot password?": "ลืมรหัสผ่าน/สร้างรหัสผ่านใหม่ คลิกที่นี่",
  "Forgot password": "ลืมรหัสผ่าน/สร้างรหัสผ่านใหม่",
  "Click here": "คลิกที่นี่",
  "to join HubSwitch without login": "เพื่อเข้าร่วม HubSwitch โดยที่ไม่ต้อง Log in",
  "We will send OTP to your email for setting a new password": 'เราจะส่ง OTP ไปที่อีเมลของคุณ เพื่อให้คุณสร้างรหัสผ่านใหม่',
  "Reward": "รางวัล",
  "Coupon": "คูปอง",
  "History": "ประวัติการใช้งาน",
  "Account": "บัญชีขอฉัน",
  "Recently Visited": "ดูล่าสุด",
  "About": "เกี่ยวกับเรา",
  "Help": "ศูนย์ช่วยเหลือ",
  "Point": "คะแนน",
  "Regular": "ระดับสมาชิก",
  "Earn 735 point to Gold": "อีก 735 คะแนน ถึงระดับโกลด์",
  "No Expiration": "ไม่มีวันหมดอายุ",
  "Valid Until 25/06/2020 (2 Months From Last Use)": "ใช้ได้ถึงวันที่ 25/06/2020 (2 เดือน จากการใช้งานล่าสุด)",
  "Expires": "หมดอายุ",
  "Used": "จำนวนครั้ง",
  "Duration": "ช่วงเวลา",
  "Lastest": "ล่าสุด",
  "Sort by": "เรียงโดย",
  "Booking ID": "รหัสการจอง",
  "See more": "เพิ่มเติม",
  "Private Account": "ตั้งเป็นบัญชีส่วนตัว",
  "Tags": "แท็ก",
  "When your account's tag is not allowed. Only people you approve can tag your photos and videos on this app": "เลือกว่าใครสามารถแท็กคุณในรูปภาพและวิดีโอบ้าง เมื่อมีคนพยายามแท็กคุณ พวกเขาจะเห็นว่าคุณไม่อนุญาตให้ทุกคนแท็ก",
  "Social Media": "บัญชีที่เชื่อมโยง",
  "Comments": "การแสดงความคิดเห็น",
  "Review": "การรีวิว",
  "Block Accounts": "บัญชีที่ถูกบล็อก",
  "Mute Accounts": "บัญชีที่คุณปิดการแจ้งเตือนไว้",
  "Report a Problem": "รายงานปัญหา",
  "Help Center": "ศูนย์ช่วยเหลือ",
  "Privacy and Security Help": "ความเป็นส่วนตัวและความปลอดภัย",
  "Language": "ภาษา",
  "Notification": "การแจ้งเตือน",
  "Security": "ความเป็นส่วนตัว",
  "Chat": "แชท",
  "Location": "สถานที่",
  "Ads": "โฆษณา",
  "Reminders": "การเตือนความจำ",
  "Follower Requests": "คำขอเป็นผู้ติดตาม",
  "Follower activity": "กิจกรรมของผู้ติดตาม",
  "Likes": "การกดถูกใจ",
  "Tags": "การแท็ก",
  "Recommended Posts": "โพสต์ที่แนะนำ",
  "Storage": "คลังเก็บของ",
  "Password": "รหัสผ่าน",
  "Do Not Disturb": "ห้ามรบกวน",
  "Show Preview": "แสดงตัวอย่าง",
  "Show message previews in alerts and banners when you are not using the app.": "แสดงตัวอย่างข้อความในการแจ้งเตือนและแบนเนอร์เมื่อคุณไม่ได้ใช้แอป",
  "Sound While Using App": "เสียงขณะกำลังใช้แอป",
  "Location Survices": "บริการตำแหน่งที่ตั้ง",
  "Allow this app to access this device’s precise location": "อนุญาตให้แอปนี้เข้าถึงตำแหน่งที่ตั้งที่แม่นยำของอุปกรณ์นี้",
  "While Using": "ขณะกำลังใช้",
  "Location History": "ประวัติตำแหน่งที่ตั้ง",
  "Allow this app to build a history of precise locations recived through Location Survice on your devices.": "อนุญาตให้แอปนี้สร้างประวัติตำแหน่งที่ตั้งที่แม่นยำ ที่ได้รับผ่านบริการตำแหน่งที่ตั้งในอุปกรณ์ของคุณ",
  "View Your Location History": "ดูประวัติตำแหน่งที่ตั้งของคุณ",
  "Ad Activity": "กิจกรรมโฆษณา",
  "About Ads": "เกี่ยวกับโฆษณา",
  "Points Received": "คะแนนที่ได้รับ",
  "Points Used": "คะแนนที่ใช้แล้ว",
  "Ref no. 0000002": "หมายเลขอ้างอิง 0000002",
  "Prize": "ราคา",
  "Note": "เพิ่มเติม",
  "Manually Approve Tags": "การอนุมัติแท็กด้วยตนเอง",
  "No available date for this stylist.": "ไม่มีคิวว่างสำหรับช่างคนนี้",
  "Pending Tags": "แท็กที่รอดำเนินการ",
  "Tagged Posts": "โพสต์ที่คุณถูกแท็ก",
  "Edit": "แก้ไข",
  "Current version": "เวอร์ชันปัจจุบัน",
  "Terms and Conditions": "เงื่อนไขในการให้บริการ",
  "Privacy Policy": "นโยบายความเป็นส่วนตัว",
  "You agree to": "คุณตกลงว่าเวลาในการใช้บริการ คุณจะยอมรับและปฏิบัติตาม",
  "Open source licenses": "",
  "Suggested": "ร้านค้าแนะนำ",
  "Recently": "การจองล่าสุด",
  "Popular Deals": "ดีลสุดร้อนแรง",
  "You may like": "ที่คุณอาจจะชอบ",
  "Nearest": "บริเวณใกล้เคียง",
  "BOOK NOW": "จองตอนนี้",
  "View all 8 comments": "ดูความคิดเห็นทั้ง 8 รายการ",
  "Redeem coupon": "กดรับคูปองส่วนลด",
  "Change stylist": "เปลี่ยนช่างทำผม",
  "Survice": "บริการ",
  "Total": "รวมทั้งหมด",
  "Book Again": "จองอีกครั้ง",
  "More Details": "รายละเอียดเพิ่มเติม",
  "Date": "วันที่",
  "Time": "เวลา",
  "Stylist": "ช่างทำผม",
  "Hive’s stylist": "ช่างทำผมของ Hive",
  "Please select the blanch": "กรุณาเลือกสาขาที่ต้องการรับบริการ",
  "Search to Switch Beauty": "ค้นหาร้านค้า",
  "Follow": "ติดตาม",
  "Open now": "เปิดบริการ",
  "Saved coupon": "บันทึกคูปองแล้ว",
  "Save coupon": "บันทึกคูปอง",
  "Post": "โพสต์",
  "Stylist": "ช่างทำผม",
  "Gallery": "แกลลอรี",
  "Following": "กำลังติดตาม",
  "Remove Me From Post": "ลบฉันออกจากโพสต์",
  "Tag Options": "ตัวเลือกการแท็ก",
  "Show on My Profile": "แสดงบนโปรไฟล์ของฉัน",
  "Learn More": "เรียนรู้เพิ่มเติม",
  "Report": "รายงานปัญหา",
  "Ratings & Reviews": "เรทติ้ง & รีวิว",
  "Ambience": "บรรยากาศ",
  "Cleanliness": "ความสะอาด",
  "Staff": "พนักงาน",
  "Value": "ความคุ้มค่า",
  "Read all reviews": "ดูความคิดเห็นทั้งหมด",
  "Stylist’s Gallery": "แกลลอรีของช่างทำผม",
  "Morning": "ช่วงเช้า",
  "Afternoon": "ช่วงกลางวัน",
  "Evening": "ช่วงเย็น",
  "Booking Details": "รายละเอียดการจอง",
  "Booking Service": "บริการการจอง",
  "Stylist Datials": "รายละเอียดของช่าง",
  "Final Confirmation": "ยืนยันการจอง",
  "More Details": "รายละเอียดเพิ่มเติม",
  "Add Promotion": "เพิ่มโปรโมชัน",
  "Please show your QR code before 15 minutes to confirm service": "กรุณานำ QR Code นี้มาแสดงที่ร้าน เพื่อยืนยันการเข้ารับบริการเวลาประมาณ 15 นาที",
  "Report": "รายงานปัญหา",
  "Mute": "ปิดการแจ้งเตือน",
  "Unfollow": "เลิกติดตาม",
  "Copy link": "คัดลอกลิงค์",
  "Turn on Post Notification": "เปิดการแจ้งเตือนโพสต์",
  "Search new trends": "ค้นหาเทรนด์ใหม่",
  "Processing": "กำลังรอดำเนินการ",
  "Recent": "ล่าสุด",
  "See all": "ดูเพิ่มเติม",
  "Mute Messages": "ปิดการแจ้งเตือนข้อความ",
  "Shared": "แชร์รูปภาพ",
  "My List": "รายการของฉัน",
  "Following": "กำลังติดตาม",
  "Gallery": "แกลลอรี",
  "Queue": "คิวของฉัน",
  "Review": "รีวิวของฉัน",
  "Edit Profile": "แก้ไขโปรไฟล์",
  "Booking again": "จองอีกครั้ง",
  "Name": "ชื่อ",
  "Birthday": "วันเกิด",
  "Gender": "เพศ",
  "Phone": "เบอร์โทรศัพท์",
  "Email": "อีเมล",
  "Allergy": "ประวัติการแพ้",
  "Search more": "ค้นหาเพิ่มเติม",
  "Search": "ค้นหา",

  "Home":"หน้าแรก",
  "Payment": "การชำระเงิน",
  "Profile": "โปรไฟล์",
  "Feed": "ฟีด",
  "Book now": "จองเลย",

  "Setting": "ตั้งค่า",
  "Menu": "เมนู",
}

const banks = {
  'kasikorn': {
      th: 'ธนาคารกสิกรไทย',
      en: 'Kasikorn Bank'
  },
  'bangkok': {
      th: 'ธนาคารกรุงเทพ',
      en: 'Bangkok Bank'
  },
  'sc-b': {
      th: 'ธนาคารไทยพาณิชย์',
      en: 'SCB Bank'
  },
  'krungsri': {
      th: 'ธนาคารกรุงศรีอยุธยา',
      en: 'Krungsri Bank'
  },
  'krungthai': {
      th: 'ธนาคารกรุงไทย',
      en: 'Krung Thai Bank '
  },
  'tmb': {
      th: 'ธนาคารทหารไทย',
      en: 'TMB Bank'
  },
  'cimb': {
      th: 'ธนาคารซีไอเอ็มบี',
      en: 'CIMB Bank'
  },
  'uob': {
      th: 'ธนาคารยูโอบี',
      en: 'UOB Bank'
  },
  'lh': {
      th: 'ธนาคารแลนด์ แอนด์ เฮาส์',
      en: 'Land and Houses Bank'
  },
  'standard': {
      th: 'ธนาคารสแตนดาร์ดฯ',
      en: 'Standard Chartered Bank'
  },
  'thanachart': {
      th: 'ธนาคารธนชาติ',
      en: 'Thanachart Bank'
  },
  'gove-sav': {
      th: 'ธนาคารออมสิน',
      en: 'Government Savings Bank'
  },
  'kiatnakin': {
      th: 'ธนาคารเกียรตินาคิน',
      en: 'Kiatnakin Bank'
  },
  'tisco': {
      th: 'ธนาคารทิสโก้',
      en: 'Tisco Bank'
  },
}

export default th
