import React, {useState, useEffect} from 'react';
import Chart from "react-apexcharts";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {
    Container,
    Row,
    Col,
    Card,
    Table,
    Form,
    Button,
    Modal,
    Dropdown
} from 'react-bootstrap';

import Aux from "../../hoc/_Aux";
import DEMO from "../../store/constant";
import avatar2 from '../../assets/images/user/avatar-2.jpg';
import avatar3 from '../../assets/images/user/avatar-3.jpg';
import avatar4 from '../../assets/images/user/avatar-4.jpg';
import _ from 'lodash'
import postImage from '../../assets/images/gallery-grid/masonry-6.jpg';
import api from '../../utils/Api'
import Colors from '../../utils/styles/Colors'
import {can} from '../../utils/Helpers'
import {numberFormat, getQueryParams} from '../../utils/Functions'
import moment from 'moment'
import t from '../../locales/trans'

const numberStatsHeight = 175
const graphHeight = 350
const graphColors = [Colors.secondary, Colors.primaryDark, Colors.primary, Colors.gray300, Colors.gray200]
const labelWidth = 200
const rand = (min, max) => {
  return Math.floor(Math.random() * max) + min
}

export default function OfficerYearlyExpenseChart({location}) {
  let params = getQueryParams(location.search, true)

  const [data, setData] = useState([])
  const [stats, setStats] = useState({})
  const {paid, total} = stats

  useEffect(()=>{
    api.request('salary/stats/yearly', ({monthly, total, paid})=>{
      setData(monthly)
      setStats({total, paid})
    }, {}, 'GET', {bearerToken: params.api_token})
  }, [])

  return (
    <Aux>
        <div className="full-screen bg-white">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <div className="row-center mb-3">
                  <div className="row-vcenter">
                    <i className="fa fa-circle text-secondary mr-1"></i>
                    <small>ชำระแล้ว: <strong className='text-secondary'>{numberFormat(paid) + ' THB'}</strong></small>
                  </div>
                  <div className="row-vcenter ml-4">
                    <i className="fa fa-circle text-muted mr-1"></i>
                    <small>ค่าใช้จ่ายรวม: <strong className='text-muted'>{numberFormat(total) + ' THB'}</strong></small>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <ResponsiveContainer width="100%" height={graphHeight*0.75}>
                    <BarChart data={data} barSize={10} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                        <defs>
                            <linearGradient id="colorBar1" x1="0" y1="0" x2="1" y2="0">
                                <stop offset="1%" stopColor="#1de9b6" stopOpacity={1}/>
                                <stop offset="99%" stopColor="#1dc4e9" stopOpacity={1}/>
                            </linearGradient>
                            <linearGradient id="colorBar2" x1="0" y1="0" x2="1" y2="0">
                                <stop offset="1%" stopColor="#899FD4" stopOpacity={1}/>
                                <stop offset="99%" stopColor="#A389D4" stopOpacity={1}/>
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip

                          formatter={(value, name, props)=> {
                            return [numberFormat(value), 'รายจ่ายรวม']
                          }}
                        />
                        <Bar dataKey="sum" stackId="bar" fill={Colors.secondary} radius={[10, 10, 10, 10]} />
                    </BarChart>
                </ResponsiveContainer>
            </Col>
          </Row>
          </Container>
        </div>
    </Aux>
  )
}
