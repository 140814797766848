import * as React from 'react';
import {Form} from 'react-bootstrap';

export default function Content ({small}) {
  return (<Form>
    <Form.Label style={{fontSize: '11px'}}>ข้อความที่ถูกใส่ไว้ในรายละเอียดภาพนี้ จะไม่ถูกเผยแพร่ให้สาธารณะ
  และลูกค้าเจ้าของภาพเห็น ถึงแม้จะทำการเผยแพร่ภาพลงสู่สาธารณะ
    </Form.Label>
  </Form>)
}
