import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { createStore } from 'redux';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {Route, Switch} from "react-router-dom";

import App from './App/index';
import {__isDebug} from './utils/Api'
import * as serviceWorker from './serviceWorker';
import reducer from './store/reducer';
import config from './config';
import TagManager from 'react-gtm-module'

import './assets/scss/style.scss';
import 'react-image-lightbox/style.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const tagManagerArgs = {
    gtmId: 'GTM-MX7ZGLR'
}

if (!__isDebug)
TagManager.initialize(tagManagerArgs)

const store = createStore(reducer);
const container = document.getElementById('root');
const root = createRoot(container);

const app = (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
          <BrowserRouter basename={config.basename}>
              <Switch>
                <Route path="/" component={App}/>
              </Switch>
          </BrowserRouter>
      </LocalizationProvider>
    </Provider>
);

root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
