export default {
    items: [
        {
            id: 'support',
            title: '',
            type: 'group',
            icon: 'icon-support',
            children: [
                {
                    id: 'home',
                    title: 'Home',
                    type: 'item',
                    url: '/',
                    classes: 'nav-item',
                    icon: 'feather icon-home'
                },
                {
                    id: 'calendar',
                    title: 'Calendar',
                    type: 'item',
                    url: '/calendar',
                    classes: 'nav-item',
                    icon: 'feather icon-calendar'
                },
                {
                    id: 'list-package',
                    title: 'List Package',
                    type: 'item',
                    url: '/list-package',
                    classes: 'nav-item',
                    icon: 'feather icon-list'
                },
                {
                    id: 'lab',
                    title: 'Lab',
                    type: 'item',
                    url: '/lab',
                    classes: 'nav-item',
                    icon: 'feather icon-clipboard'
                },
                {
                    id: 'user-list',
                    title: 'CRM',
                    type: 'item',
                    url: '/crm',
                    classes: 'nav-item',
                    icon: 'feather icon-users'
                },
                {
                    id: 'page',
                    title: 'Page',
                    type: 'item',
                    url: '/page',
                    classes: 'nav-item',
                    icon: 'feather icon-layout'
                },
                {
                    id: 'chat',
                    title: 'Message',
                    type: 'item',
                    url: '/message',
                    classes: 'nav-item',
                    icon: 'feather icon-message-square',
                },
                {
                    id: 'payment',
                    title: 'Payment',
                    type: 'item',
                    url: '/payment',
                    classes: 'nav-item',
                    icon: 'feather icon-shield'
                },
                {
                    id: 'account',
                    title: 'Account',
                    type: 'item',
                    url: '/account',
                    classes: 'nav-item',
                    icon: 'fa fa-dollar-sign'
                },
                {
                    id: 'inventory',
                    title: 'Inventory',
                    type: 'item',
                    url: '/inventory',
                    classes: 'nav-item',
                    icon: 'fa fa-boxes'
                },
                {
                    id: 'human-resource',
                    title: 'Human Resource',
                    type: 'item',
                    url: '/human-resources',
                    classes: 'nav-item',
                    icon: 'fa fa-user-friends'
                },
                {
                    id: 'x-ray',
                    title: 'X-ray Photo IOS',
                    type: 'item',
                    url: '/x-ray/extra-oral-film',
                    classes: 'nav-item',
                    icon: 'fa fa-film'
                },
            ]
        }
    ]
}
