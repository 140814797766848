import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {Row, Col, Container, Card, Table, Button, Form, InputGroup, FormControl,
   Accordion, Modal, Badge, Dropdown, Fade} from 'react-bootstrap';
import '../../assets/scss/style.scss';
import Aux from "../../hoc/_Aux";
import Fullscreen from "react-full-screen";

export default function Role({onNext, onCancel}) {
  const [role, setRole] = useState('business_owner')

  return (
    <Aux>
        <div className="full-screen bg-white">
          <Container fluid>
            <Row>
              <Col className="register-process-indicator column-center py-4" sm={12} lg={5}>
                <h3 className="text-white">ตำแหน่งของคุณ</h3>
                <i className="fa fa-users text-white register-process-indicator-icon"
                ></i>
                <div className="row-vcenter">
                  <div className="register-process-indicator-bar" />
                  <div className="register-process-indicator-bar active mx-2" />
                  <div className="register-process-indicator-bar" />
                </div>
              </Col>
              <Col sm={12} lg={7}>
                <div className="d-flex flex-column justify-content-around align-items-center h-100">
                  <Row style={{width: 500, maxWidth: '100%'}}>
                    <Col xs={12}>
                        <h3 className="text-primary text-center">ตำแหน่งของคุณในธุรกิจ</h3>
                    </Col>
                    <Col xs={12} className="row-center mt-5 mb-4">
                      <div onClick={()=>setRole('business_owner')} className={"role-selector cursor-pointer row-center shadow"+(role=='business_owner'?' role-selector-active':'')}>
                        <span>เจ้าของธุรกิจ</span>
                      </div>
                    </Col>
                    <Col xs={12} className="row-center mt-2 mb-4">
                      <div onClick={()=>setRole('admin')} className={"role-selector cursor-pointer row-center shadow"+(role=='admin'?' role-selector-active':'')}>
                        <span>แอดมินหรือฝ่ายบุคคล</span>
                      </div>
                    </Col>
                    <Col xs={12} className="text-center my-5">
                      <small>ให้เราแนะนำขั้นตอนการทำงานตำแหน่งของคุณ</small>
                    </Col>
                    <Col xs={12} className="row-center mt-3">
                      <Button onClick={()=>onCancel()} className="mr-3" size="md" variant="dark">Back</Button>
                      <Button onClick={()=>onNext({role})} size="md" variant="secondary">Next</Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
    </Aux>
  )
}
